import styled from "styled-components";
import {
	TABLET_M_BP,
	DESKTOP_SM_BP,
	DESKTOP_M_BP,
	TABLET_BP,
	MOBILE_L_BP,
	MOBILE_SM_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

export const COLORS = {
	HEADING_DARK: { base: "primary", color: "neutral-nightshade", shade: "800" },
	HEADING_LIGHT: { base: "primary", color: "neutral", shade: "white" },
	TEXT_DARK: { base: "primary", color: "neutral-nightshade", shade: "600" },
	TEXT_LIGHT: { base: "primary", color: "neutral", shade: "white" },
};

export const FooterWrapper = styled.div`
	grid-column: span 12;
	padding-top: 80px;
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding-top: 16px;
		grid-column: span 6;
	}
`;

export const ValueIconBarWrapper = styled.div`
	display: flex;
	margin: 40px 24px;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1.5rem 2rem;
	.item-container {
		display: flex;
		align-items: center;
		gap: 0.75rem;
		& svg {
			width: 2rem;
			height: 2rem;
		}
		& svg * {
			stroke-width: 1;
		}
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		flex-direction: row;
		align-items: center;
		flex-wrap: wrap;
		justify-content: unset;
		.item-container {
			gap: 0.5rem;
			& svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
		.item-container p {
			white-space: nowrap;
		}
	}
`;

export const HeroBannerVideoPlayer = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
	${(props) => (props.darkenVideo ? `filter: brightness(0.4);` : ``)}
`;

export const HeroBannerWRWrapper = styled.div`
	padding: 80px 96px;
	padding-top: 136px;
	${(props) => (props.hasFooter ? `padding-bottom: 0px;` : ``)}
	&.up-banner-wrapper {
		min-height: 600px;
	}
	display: grid;
	gap: 2rem;
	position: relative;
	${(props) =>
		props.imagePos === "left"
			? `padding-bottom : 0;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 80px;
      padding-bottom:0;
      background-color: white;
    }`
			: ""}
	grid-template-columns: repeat(12, minmax(0,1fr));
	${(props) =>
		props.borderColor && `border-bottom: 12px solid ${props.borderColor}`};
	.cta-button.university {
		width: 210px;
		margin-bottom: 1.5rem 0;
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			width: 120px;
		}
	}
	.grid-1 {
		align-self: center;
		z-index: 1;
		grid-column-start: 1;
		grid-column-end: ${(props) =>
			!props.isCenter || props.hasBackgroundImage ? 6 : 13};
		${(props) =>
			props.isCenter && !props.hasBackgroundImage
				? `
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    `
				: null};
		&.up-hero-banner-wr:not(.has-right-section) {
			grid-column-end: 8;
		}
	}
	.grid-2 {
		align-self: center;
		z-index: 1;
		grid-column-start: 7;
		grid-column-end: 13;
		height: fit-content;
		position: relative;
		${(props) =>
			props.imagePos === "right"
				? !props.stretchImage
					? `
     justify-self: end;
    `
					: null
				: null};
		&.image-left {
			align-self: flex-start;
			margin-top: 52px;
		}
	}
	.rightsection-wrapper {
		display: flex;
		flex-direction: column;
		gap: 1rem;

		& > .partnership {
			display: flex;
			align-items: center;
			gap: 1rem;
		}
		& > .rightsection-buttons {
			margin-top: 0.5rem;
			& > :first-child {
				margin-right: 1rem;
			}
		}
	}

	${({ hasDarkBackground }) =>
		hasDarkBackground &&
		`
    ul {
      color: var(--theme-light-font-color, var(--primary-neutral-white));
    }
  `}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		overflow: unset;
		padding: 40px 24px;
		padding-top: 92px;
		${(props) => (props.hasFooter ? `padding-bottom: 0px;` : ``)}
		grid-template-columns: repeat(6, minmax(0,1fr));
		.grid-1 {
			grid-column-start: 1;
			grid-column-end: 7;
			${(props) => (props.imagePos === "left" ? `order:2;` : null)}
		}
		.grid-2 {
			grid-column-start: 1;
			grid-column-end: 7;
			${(props) => (props.hasImageCard ? "margin-bottom:164px;" : "")}
			${(props) =>
				props.imagePos === "right"
					? `
        justify-self: center;
        `
					: null};
			&.image-left {
				margin-top: 0px;
			}
		}
	}
	.flex-row-center {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 16px;
		gap: 1rem;

		.label-overflow {
			display: flex;
			gap: 0.5rem;
			flex-wrap: wrap;
		}
	}
	.hero-image {
		position: relative;
		max-width: 100%;
		display: flex;
		align-items: center;
		&:not(.brand-logo) {
			border-radius: 16px;
		}
		@media (max-width: ${TABLET_BP}px) {
			margin-left: 8px;
		}
	}
	.video-image img {
		max-width: 100%;
		border-radius: 16px;
	}
	.hero-image-card {
		min-width: 283px;
		max-width: 297px;
		border-radius: 16px;
		box-shadow:
			0px 78px 31px rgba(0, 0, 0, 0.01),
			0px 44px 26px rgba(0, 0, 0, 0.02),
			0px 20px 20px rgba(0, 0, 0, 0.03),
			0px 5px 11px rgba(0, 0, 0, 0.04),
			0px 0px 0px rgba(0, 0, 0, 0.04);

		padding: 16px;
		background-color: white;
		position: absolute;
		bottom: 0;
		transform: translate(-40%, -25%);
		transition: transform 0.3s ease-in-out;

		@media (max-width: ${TABLET_BP}px) {
			max-width: 300px;
			transform: translate(-3%, 75%);
		}
	}
	@media only screen and (min-width: ${DESKTOP_M_BP}px) {
		.brand-logo {
			width: 125px;
			height: 32px;
		}
	}
	@media only screen and (min-width: ${MOBILE_L_BP}px) and (max-width: ${TABLET_M_BP}px) {
		.brand-logo {
			width: 125px;
			height: 32px;
		}
	}
	.hero-image-card.alumni-card {
		box-sizing: unset;
	}
	.card-flow {
		margin-bottom: 8px;
		#hero-alumni-title {
			color: var(--primary-neutral-nightshade-800);
		}
		#hero-alumni-subtitle {
			color: var(--primary-neutral-nightshade-600);
		}
		#hero-alumni-description {
			color: var(--secondary-gray-dark);
		}
	}
	#list-pointer-icon {
		margin-right: 26px;
	}
	#brand-bar-label {
		margin: 20px 0;
		text-transform: uppercase;
	}
	#hero-banner-heading {
		margin-bottom: 8px;
		font-size: 3rem;
		@media (max-width: ${TABLET_BP}px) {
			font-size: 2.25rem;
		}
	}
	#hero-banner-subheading {
		margin-bottom: ${({ removeAdditionalTextSpace }) =>
			removeAdditionalTextSpace ? "8px" : "32px"};
		@media only screen and (max-width: ${TABLET_M_BP}px) {
			font-weight: 400;
		}
	}
	#hero-banner-text {
		margin: ${({ removeAdditionalTextSpace }) =>
			removeAdditionalTextSpace ? "8px 0 24px" : "24px 0"};
	}
	.hero-banner-list {
		margin-top: 24px;
	}

	@media only screen and (min-width: 2000px) {
		${(props) =>
			props.stretchImage
				? `
     .grid-1 {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .grid-2 {
      grid-column-start: 5;
      grid-column-end: 13;
    }
    `
				: null};
	}
	@media only screen and (max-width: ${DESKTOP_SM_BP}px) and (min-width: ${TABLET_M_BP}px) {
		padding: 80px 64px;
	}
	@media only screen and (max-width: ${DESKTOP_M_BP}px) and (min-width: ${TABLET_M_BP}px) {
		.alumni-card {
			transform: translate(-30%, -10%);
		}
	}
	${({ hideProgramInfoInMobile }) =>
		hideProgramInfoInMobile &&
		`
  @media only screen and (max-width: ${MOBILE_L_BP}px) {
    .program-info {
      display: none;
    }
    &.hero-banner-wr.up-banner-wrapper{
      min-height: 0px;
    }
  }
  `}

	.outer-margin-wrap {
		background: transparent;
	}
`;

export const Flow = styled.div`
	& > *:not(:last-child) {
		margin-bottom: ${(props) => props.gap || "1rem"};
	}
	.hero-banner-logo {
		max-height: 30px;
	}
	@media (max-width: ${TABLET_BP}px) {
		display: flex;
		flex-direction: column;
		.university.cta-button {
			order: -1;
		}
	}
`;

export const HeroFormWrapper = styled.div`
	.form-with-background {
		${(props) =>
			props.backgroundColor
				? `
        background: ${props.backgroundColor};
        box-shadow: 0px 78px 31px rgba(0, 0, 0, 0.01), 0px 44px 26px rgba(0, 0, 0, 0.02), 0px 20px 20px rgba(0, 0, 0, 0.03), 0px 5px 11px rgba(0, 0, 0, 0.04), 0px 0px 0px rgba(0, 0, 0, 0.04);
        border-radius:8px;
        padding:1.5rem;
      `
				: ``}
		${(props) =>
			!props.hasProgramDropdown &&
			`
      &x> button{
        width: 190px;
      }`}

    & option[value=""][disabled] {
			display: none;
		}

		.form-success-message,
		.form-error-message {
			margin: 0;
			margin-top: 8px;
		}
		.form-success-message {
			color: ${(darkBg) =>
				!darkBg
					? `var(--primary-neutral-nightshade-800)`
					: `var(--primary-neutral-white)`};
		}
		.form-error-message {
			color: ${(darkBg) =>
				!darkBg ? `var(--secondary-red-300)` : `var(--secondary-red-100)`};
		}
	}
`;
export const ImageSectionWrapper = styled.div`
	.right-hero-image {
		border-radius: 16px;
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.right-hero-image {
			${(props) => !props.hasCard && `margin:0px`}
		}
	}

	@media only screen and (min-width: ${DESKTOP_M_BP}px) {
		${(props) =>
			props.hasCard &&
			`.right-hero-image {
      width: 506px;
      height: 525px;
    }`}
	}
	@media only screen and (min-width: ${MOBILE_L_BP}px) and (max-width: ${TABLET_M_BP}px) {
		${(props) =>
			props.hasCard &&
			`.right-hero-image {
      width: 506px;
      height: 525px;
    }`}
	}

	${(props) =>
		props.stretchImage
			? ` height: 525px;
  .right-hero-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }`
			: ""}
`;

export const VideoWrapper = styled.div`
	.video-image img {
		object-fit: cover;
		width: 100%;
		aspect-ratio: 16/9;
	}

	.video-modal {
		width: 70%;
		height: fit-content;
		transform: translate(-50%, -50%);
		top: 50%;
		left: 50%;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		.video-modal {
			width: calc(100% - 160px);
			height: auto;
			padding: 80px 0;
		}
		.video-modal .closeButtonWrapper {
			right: 0;
		}
		img {
			height: 50vw;
			max-height: unset;
		}
	}

	@media screen and (orientation: landscape) and (min-device-width: ${MOBILE_SM_BP}px) and (max-device-width: ${TABLET_M_BP}px) {
		.video-modal {
			width: calc(100% - 240px);
			height: auto;
			padding: 80px 10%;
		}
	}
`;
